.amplify-button--primary {
  background-color: #c4d203;
  color: #28002d;
  font-size: 16px;
}

.amplify-field-group__field-wrapper input,
.amplify-field__show-password {
  border-radius: 0;
  display: block;
  width: 100%;
  height: 44px;
  line-height: normal;
  padding: 0px 16px;
  font-size: 16px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e7e7e7;
  border-radius: 0px;
  box-shadow: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.amplify-select__wrapper select {
  border-radius: 0;
  width: 100%;
  line-height: normal;
  font-size: 16px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e7e7e7;
  border-radius: 0px;
  box-shadow: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  color: #6c6c6c;
  height: 44px;
  align-items: center;
}

.amplify-label {
  font-size: 16px;
  line-height: 24px;
}

[data-amplify-authenticator] [data-amplify-router] {
  background-color: rgb(246, 246, 245);
  box-shadow: none;
  border-color: none;
  border-width: none;
  border-style: none;
}

[data-amplify-authenticator] .signUpHeader {
  font-size: 16px;
  margin: 8px 0 0 32px;
  color: inherit;
}
.bigHeader {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin: 8px 0 0 32px;
}

[data-amplify-form] {
  padding-top: 16px;
}

.in-signup-link {
  color: rgb(0, 0, 0);
}

.amplify-checkboxfield {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-style: italic;
  cursor: pointer;
  user-select: none;
}

.amplify-tabs {
  display: none;
}

.footerContainer {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

#landing-auth .amplify-button--primary:disabled,
#landing-auth .amplify-button--primary[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.fieldDesc {
  font-style: italic;
  color: rgb(131, 140, 133);
  font-size: 14px;
  line-height: 18px;
  margin-top: -8px;
}

form[data-amplify-authenticator-resetpassword=''] .amplify-button--link {
  display: none;
}
.toSignIn {
  font-weight: var(--amplify-font-weights-normal);
  font-size: 14px;
  border-width: 0;
  background-color: var(--amplify-components-button-link-background-color);
  cursor: pointer;
  color: rgb(4, 125, 149);
  padding: 8px;
}
.toSignIn:hover {
  background-color: var(--amplify-colors-brand-primary-10);
}
