.accordion-section {
  display: flex;
  flex-direction: column;
  border-bottom: solid 2px rgba(0, 0, 0, 0);
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion-section .accordion {
  background-color: #fff;
  color: #2d0127;
  cursor: pointer;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  outline: none;
  /*transition: background-color 0.6s ease;*/
  font-size: inherit;
  font-family: inherit;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion-section .accordion:hover,
.accordion-section .active {
  /*background-color: #ccc;*/
}

.accordion-section .active + div {
  margin-top: -10px;
  margin-bottom: -10px;
}

/* Style the accordion content title */
.accordion-section .accordion-title {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 16px;
  font-weight: bold;
  color: inherit;
  padding-right: 20px;
  text-align: left;
}

/* Style the accordion arrow icon */
.accordion-section .accordion-icon {
  margin-left: auto;
  /*transition: transform 0.6s ease;*/
}

/* Style to rotate icon when state is active */
.accordion-section .rotate {
  /*transform: rotate(90deg);*/
}

/* Style the accordion content panel. Note: hidden by default */
.accordion-section .accordion-content {
  background-color: white;
  overflow: hidden;
  transition-duration: 0.4s;
  transition-delay: 0s;
  transition-property: max-height;
  transition-timing-function: ease;
}

/* Style the accordion content text */
.accordion-section .accordion-text {
  font-family: inherit;
  color: inherit;
  padding: 0px 24px;
}

@media screen and (min-width: 992px) {
  .accordion-section .accordion-text {
    /*max-width: calc(100% - 80px);*/
  }
}

.accordion-section .accordion-text p:not(:last-child) {
  margin: 0 0 20px;
}
