@font-face {
  font-family: 'Novozymes';
  src: url('../fonts/Novozymes-Bd.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Novozymes-Bd.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Novozymes-Bd.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/Novozymes-Bd.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Novozymes-Bd.svg#Novozymes-Bd') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Novozymes';
  src: url('../fonts/Novozymes-Rg.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Novozymes-Rg.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Novozymes-Rg.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/Novozymes-Rg.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Novozymes-Rg.svg#Novozymes-Rg') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Novozymes';
  src: url('../fonts/Novozymes-Tn.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Novozymes-Tn.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/Novozymes-Tn.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/Novozymes-Tn.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/Novozymes-Tn.svg#Novozymes-Tn') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

#root {
  font-family: 'Novozymes', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
